import React, { useCallback } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import { toggleReadwiseEmailPreferences } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import Switch from '../Switch';
import { AccountSettingsPage, ListItem } from './AccountSettingsPage';
import styles from './EmailPreferencesPage.module.css';

export const EmailPreferencesPage = () => {
  return (
    <AccountSettingsPage title="Product Emails">
      <DailySummary />
      <WisereadsNewsletter />
      <PublicBetaUpdate />
      <DailyReview />
      <RecipientEmailAddress />
    </AccountSettingsPage>
  );
};

const RecipientEmailAddress = () => {
  const profile = globalState(useCallback((state) => state.client?.profile, []));
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email = '' } = profile ?? {};

  return (
    <section className={styles.addressSection}>
      <div>
        Email notifications on this page are sent to <strong>{email}</strong>
      </div>
    </section>
  );
};

const DailySummary = () => {
  const dailySummary = globalState(
    useCallback(
      (state) => !state.client.profile?.email_preferences?.unsubbed_from_daily_summary_emails,
      [],
    ),
  );

  return (
    <ListItem
      title="Summaries Email"
      subtitle="Summaries of each document you save to your Reader Library during the previous 24 hours."
    >
      <Switch
        rootProps={{
          checked: dailySummary,
          onCheckedChange: () =>
            toggleReadwiseEmailPreferences({
              stateUpdateOptions: { userInteraction: 'click' },
              emailPreferenceKey: 'unsubbed_from_daily_summary_emails',
              prettyNameForToast: 'Summaries Email',
            }),
        }}
      />
    </ListItem>
  );
};

const WisereadsNewsletter = () => {
  const wisereadsNewsletter = globalState(
    useCallback((state) => !state.client.profile?.email_preferences?.unsubbed_from_wisereads_emails, []),
  );

  return (
    <ListItem
      title="Wisereads Newsletter"
      subtitle="Weekly roundup of the most highlighted content, exclusive ebooks, RSS feeds, and more."
    >
      <Switch
        rootProps={{
          checked: wisereadsNewsletter,
          onCheckedChange: () =>
            toggleReadwiseEmailPreferences({
              stateUpdateOptions: { userInteraction: 'click' },
              emailPreferenceKey: 'unsubbed_from_wisereads_emails',
              prettyNameForToast: 'Wisereads Newsletter',
            }),
        }}
      />
    </ListItem>
  );
};

const PublicBetaUpdate = () => {
  const publicBetaUpdate = globalState(
    useCallback((state) => !state.client.profile?.email_preferences?.unsubbed_from_feedback_emails, []),
  );

  return (
    <ListItem title="Public Beta Update" subtitle="Overview of new Reader features and improvements.">
      <Switch
        rootProps={{
          checked: publicBetaUpdate,
          onCheckedChange: () =>
            toggleReadwiseEmailPreferences({
              stateUpdateOptions: { userInteraction: 'click' },
              emailPreferenceKey: 'unsubbed_from_feedback_emails',
              prettyNameForToast: 'Public Beta Update',
            }),
        }}
      />
    </ListItem>
  );
};

const DailyReview = () => {
  return (
    <ListItem
      title="Daily Review"
      subtitle="You can modify your email settings for the Daily Review inside of Readwise."
    >
      <a
        href="https://readwise.io/configure"
        target="_blank"
        rel="noreferrer"
        className={styles.dailyReviewSettingsLink}
      >
        Modify
      </a>
    </ListItem>
  );
};
